/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import createDocument from "./createDocument.js";
import { generateClassName } from "../../services/util.js";
import { validateEmail, validateName } from "../../services/validation.js";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant.js";
import Title from "../../components/title/Title.jsx";

const DOCUMENT_TYPES = [
  { value: "MEAgreement", label: "Meeting & Event Agreement" },
  // { value: "HyggeCoworkingAgreement", label: "Hygge Coworking Agreement" },
  { value: "CCAuthorization", label: "Credit Card Authorization" },
  // { value: "COhatchMembershipTerms", label: "Terms and Conditions of COhatch Memberships" }
];

const SendDocumentPage = () => {
  const { userState } = useUserContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // Validation states
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [validPrimaryPhone, setValidPrimaryPhone] = useState(false);

  // Additional fields for M&E Agreement
  const [market, setMarket] = useState("");
  const [location, setLocation] = useState("");
  const [meetingLocation, setMeetingLocation] = useState({ market: "", location: "", room: "" });
  const [meetingDate, setMeetingDate] = useState("");
  const [rate, setRate] = useState("");
  const [addendum, setAddendum] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");


  const [meetingStart, setMeetingStart] = useState("");
  const [meetingEnd, setMeetingEnd] = useState("");

  useEffect(() => setValidFirstName(validateName(firstName)), [firstName]);
  useEffect(() => setValidLastName(validateName(lastName)), [lastName]);
  useEffect(() => setValidEmail(validateEmail(emailAddress)), [emailAddress]);
  useEffect(() => setValidCompanyName(companyName !== ""), [companyName]);
  useEffect(() => setValidPrimaryPhone(primaryPhone !== ""), [primaryPhone]);

  // Handle market selection change
  useEffect(() => {
    setLocation(""); // Reset location when market changes
  }, [market]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSubmitting(true);

      const documentPayload = {
        firstName,
        lastName,
        emailAddress,
        companyName,
        primaryPhone,
        documentType,
        adminId: userState.id,
        meetingLocation: {
          market,
          location,
          room: meetingLocation.room
        },
        meetingDate,
        rate,
        addendum,
        meetingStart,
        meetingEnd,
      };

      const documentResponse = await createDocument(userState.accessToken, documentPayload);

      if (documentResponse.status === "success") {
        notify("Document created successfully!", "success");
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setCompanyName("");
        setPrimaryPhone("");
        setDocumentType("");
        setMarket("");
        setLocation("");
        setMeetingDate("");
        setRate("");
        setAddendum("");
        setMeetingStart("");
        setMeetingEnd("");
      } else {
        notify("Document creation failed.", "error");
      }
      window.location.reload(); // Reload the page
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };
  const isFormValid = () => {
    const baseValidation =
      validFirstName &&
      validLastName &&
      validEmail &&
      documentType &&
      market &&
      location &&
      meetingDate &&
      rate &&
      meetingStart &&
      meetingEnd &&
      meetingLocation.room;
  
    if (documentType === "MEAgreement") {
      return baseValidation; // Ensure it returns the value
    }
  
    if (documentType === "CCAuthorization") {
      return baseValidation && validCompanyName && validPrimaryPhone;
    }
  
    // If no document type, return false
    return false;
  };
  

  return (
    <div className="mx-auto max-w-5xl bg-white p-8 rounded-lg shadow-lg">
      <div className="mb-6 text-sm text-gray-700">
        <Title name="Send Documents for Signature" />
        <p className="text-base text-gray-600">
          Send documents for signature(s) by adding in the First and Last Name of the recipient as well as their email address. From there, select the type of document you’d like to send and fill in the rest of the form fields to generate the document for signature. An email will be sent to the signee after you hit submit. Once they sign the document, you will receive an email letting you know that the document is signed. A copy of the document will be saved here in COhatch+ Admin and the signee will be able to see their copy in COhatch+.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* First Name */}
        <div>
          <label htmlFor="firstname" className="block text-sm font-semibold text-gray-700">
            Recipient First Name
          </label>
          <input
            onChange={(event) => setFirstName(event.target.value)}
            value={firstName}
            id="firstname"
            name="firstname"
            type="text"
            required
            className={`mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400`}
          />
          {!validFirstName && firstName && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid first name.</p>
          )}
        </div>

        {/* Last Name */}
        <div>
          <label htmlFor="lastname" className="block text-sm font-semibold text-gray-700">
            Recipient Last Name
          </label>
          <input
            onChange={(event) => setLastName(event.target.value)}
            value={lastName}
            id="lastname"
            name="lastname"
            type="text"
            required
            className={`mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400`}
          />
          {!validLastName && lastName && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid last name.</p>
          )}
        </div>

        {/* Email Address */}
        <div>
          <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
            Recipient Email Address
          </label>
          <input
            onChange={(event) => setEmailAddress(event.target.value)}
            value={emailAddress}
            id="email"
            name="email"
            type="email"
            required
            className={`mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400`}
          />
          {!validEmail && emailAddress && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid email address.</p>
          )}
        </div>

        {/* Document Type */}
        <div>
          <label className="block text-sm font-semibold text-gray-700">Select Document Type</label>
          <Select
            options={DOCUMENT_TYPES}
            value={DOCUMENT_TYPES.find((item) => item.value === documentType)}
            onChange={(selected) => setDocumentType(selected.value)}
            placeholder="Choose a document"
            className="mt-1"
            required
          />
        </div>

        {documentType === "CCAuthorization" && (
          <>
            {/* Company Name */}
            <div>
              <label htmlFor="companyName" className="block text-sm font-semibold text-gray-700">Company Name</label>
              <input
                onChange={(event) => setCompanyName(event.target.value)}
                value={companyName}
                id="companyName"
                name="companyName"
                type="text"
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
              />
              {!validCompanyName && companyName && (
                <p className="mt-1 text-xs text-red-500">Please enter a valid company name.</p>
              )}
            </div>

            {/* Primary Phone Number */}
            <div>
              <label htmlFor="primaryPhone" className="block text-sm font-semibold text-gray-700">Primary Phone Number</label>
              <input
                onChange={(event) => setPrimaryPhone(event.target.value)}
                value={primaryPhone}
                id="primaryPhone"
                name="primaryPhone"
                type="text"
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
              />
              {!validPrimaryPhone && primaryPhone && (
                <p className="mt-1 text-xs text-red-500">Please enter a valid phone number.</p>
              )}
            </div>
          </>
        )}

        {(documentType === "MEAgreement" || documentType === "CCAuthorization") && (
          <>
            {/* Market Dropdown */}
            <div>
              <label className="block text-sm font-semibold text-gray-700">Select COhatch Market</label>
              <Select
                options={COHATCH_MARKETS.market}
                value={COHATCH_MARKETS.market.find((item) => item.value === market)}
                onChange={(selected) => {
                  setMarket(selected.value);
                  setLocation(""); // Reset location when market changes
                }}
                placeholder="Choose a market"
                className="mt-1"
                required
              />
            </div>

            {/* Location Dropdown */}
            {market && (
              <div>
                <label className="block text-sm font-semibold text-gray-700">Select COhatch Location</label>
                <Select
                  options={COHATCH_LOCATIONS.cities[0][market]?.locations || []}
                  value={location ? COHATCH_LOCATIONS.cities[0][market]?.locations.find((item) => item.value === location) : ""}
                  onChange={(selected) => setLocation(selected.value)}
                  placeholder="Choose a location"
                  className="mt-1"
                  required
                />
              </div>
            )}

            {/* Room Dropdown */}
            {location && (
              <div>
                <label className="block text-sm font-semibold text-gray-700">Select COhatch Meeting Space</label>
                <Select
                  options={COHATCH_MEETING_ROOMS[market]?.[location] || []}
                  value={meetingLocation.room ? COHATCH_MEETING_ROOMS[market][location].find((item) => item.value === meetingLocation.room) : ""}
                  onChange={(selected) => setMeetingLocation({ ...meetingLocation, room: selected.value })}
                  placeholder="Choose a meeting space"
                  className="mt-1"
                  required
                />
              </div>
            )}

            {/* Meeting Date */}
            <div>
              <label htmlFor="meetingDate" className="block text-sm font-semibold text-gray-700">Meeting Date</label>
              <input
                type="date"
                onChange={(e) => setMeetingDate(e.target.value)}
                value={meetingDate}
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
              />
            </div>

            {/* Meeting Time */}
            <div>
              <label htmlFor="meetingTime" className="block text-sm font-semibold text-gray-700">Meeting Time</label>
              <div className="flex space-x-4">
                <input
                  type="time"
                  onChange={(e) => setMeetingStart(e.target.value)}
                  value={meetingStart}
                  required
                  className="mt-1 block w-1/2 rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
                <input
                  type="time"
                  onChange={(e) => setMeetingEnd(e.target.value)}
                  value={meetingEnd}
                  required
                  className="mt-1 block w-1/2 rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
              </div>
            </div>

            {/* Rate */}
            <div>
              <label htmlFor="rate" className="block text-sm font-semibold text-gray-700">
              Rate (per Hour)
              </label>
              <div className="relative">
                <span className="absolute left-3 top-2.5 text-gray-500">$</span>
                <input
                  type="text"
                  onChange={(e) => {
                    // Remove non-numeric characters, except the period (.)
                    const value = e.target.value.replace(/[^0-9\.]/g, "");

                    // Allow only one period and digits
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(value)) {
                      setRate(value);
                    }
                  }}
                  value={rate}
                  required
                  className="mt-1 pl-6 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
              </div>
            </div>


            {/* Addendum */}
            {documentType === "MEAgreement" && (
              <div>
                <label htmlFor="addendum" className="block text-sm font-semibold text-gray-700">Addendum (Optional)</label>
                <input
                  type="text"
                  onChange={(e) => setAddendum(e.target.value)}
                  value={addendum}
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
              </div>
            )}

          </>
        )}

        <div className="text-right">
          <button
            type="submit"
            disabled={!isFormValid()}
            className={`bg-primary-400 text-white py-2 px-4 rounded-md ${!isFormValid() ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {submitting ? "Submitting..." : "Submit Document"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendDocumentPage;
