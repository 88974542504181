/* eslint-disable */
import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function getAllDocuments(accessToken, userId, page, limit) {
    const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/send_document/all_documents?page=${page}&limit=${limit}`;
    const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
    const jsonResponse = await response.json();
    return jsonResponse;
}

export default getAllDocuments;
