/* eslint-disable capitalized-comments */

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// Login page
import Login from "../../pages/login/Login";
// 404 Not Found page
import NotFound from "../../pages/not_found/NotFound";
// 401 Not Authorized page
import NotAuthorized from "../../pages/not_authorized/NotAuthorized";
import Dashboard from "../../pages/dashboard/Dashboard";
// Dashboard page with statistics of listing, user, group, coupon, etc.
import DashboardHome from "../../pages/dashboard/DashboardHome";
import CommunityManagers from "../../pages/community_managers/CommunityManagers";
// Community Managers page to create a new user and send invitation
import CommunityManagersCreateMember from "../../pages/community_managers/CommunityManagersCreateMember";
// Community Managers page to manage COhatch members
import CommunityManagersOfficernd from "../../pages/community_managers/CommunityManagersOfficernd";
// Community Managers page to manage referrals
import CommunityManagersReferrals from "../../pages/community_managers/CommunityManagersReferals";
// Community Managers page to manage bookings
// import CommunityManagersBookings from "../../pages/community_managers/CommunityManagersBookings";
import TechnicalSupport from "../../pages/technical_support/TechnicalSupport";
import TechnicalSupportContact from "../../pages/technical_support/TechnicalSupportContact";
// Technical Support page to approve/reject group
import TechnicalSupportGroups from "../../pages/technical_support/TechnicalSupportGroups";
// Technical Support page to approve/reject property (vacation home)
// import TechnicalSupportProperty from "../../pages/technical_support/TechnicalSupportProperty";
import TechnicalSupportWorkshop from "../../pages/technical_support/TechnicalSupportWorkshop";
import TechnicalSupportFeaturedWorkshop from "../../pages/technical_support/TechnicalSupportFeaturedWorkshop";
import Administrator from "../../pages/administrator/Administrator";
// Administrator page to filter and delete user
import AdministratorUsers from "../../pages/administrator/AdministratorUsers";
// Administrator page to create/delete/update admin
import AdministratorRoles from "../../pages/administrator/AdministratorRoles";
// Administrator page to create/delete/update referral code
import AdministratorReferralCodes from "../../pages/administrator/AdministratorReferralCodes";
// Notification list page
import Notification from "../../pages/notification/Notification";
import NoAuth from "../Protect/NoAuth";
import AuthUser from "../Protect/AuthUser";
import PersistAuth from "../Protect/PersistAuth";
import { UserProvider } from "./../../context/UserContext";
import { NotificationProvider } from "../../context/NotificationContext";
// Community Managers page to update plan of user
import MemberPlan from "../../pages/community_managers/MemberPlan";
// Settings page to change password of admin
import Settings from "../../pages/settings/Settings";
// Technical Support page for featured coupon
import TechnicalSupportCoupon from "../../pages/technical_support/TechnicalSupportCoupon";
// Reset password page
import ResetPassword from "../../pages/technical_support/ResetPassword";
// Technical Support page to send reminder email
import EmailReminders from "../../pages/technical_support/EmailReminders";
//Impact
import ScholarshipApplications from "../../pages/scholarship_applications/ScholarshipApplications";
import Scholarships from "../../pages/scholarship_applications/Scholarships";
import ApplicationModal from "../../pages/scholarship_applications/ApplicationModal";
import ApplicantProfile from "../../pages/scholarship_applications/ApplicantProfile";
import ApplicationFile from "../../pages/scholarship_applications/ApplicationFile";
import ImpactSurvey from "../../pages/impact_stories/ImpactSurvey";
import ImpactStoryTimeline from "../../pages/impact_stories/ImpactStoryTimeline";
import StoryPage from "../../pages/impact_stories/StoryPage";
import ImpactDashboard from "../../pages/impact_dashboard/dashboard/ImpactDashboard";
import ImpactHome from "../../pages/impact_dashboard/dashboard/ImpactHome";
import FeaturedImpact from "../../pages/impact_dashboard/featured/FeaturedImpact";
import Events from "../../pages/impact_dashboard/events/Events";
import FeaturedStories from "../../pages/impact_dashboard/featured/FeaturedStories";
import MarketLevel from "../../pages/impact_dashboard/location_dashboard/MarketLocation";
// import ImpactReports from "../../pages/impact_dashboard/impact_report/ImpactReports";
// import ImpactReportFile from "../../pages/impact_dashboard/impact_report/ImpactReportFile";
// import AllScholars from "../../pages/impact_dashboard/dashboard/AllScholars";
import ImpactNotification from "../../pages/impact_dashboard/notifications/ImpactNotifications";
// Forgot Password reset password page
import ResetRequest from "../../pages/reset_request/ResetRequest";
// Change Password page once user clicks on email link received from Forgot Password page
import SelfResetPassword from "../../pages/reset_password/SelfResetPassword";
import LocationDashboard from "../../pages/impact_dashboard/location_dashboard/LocationDashboard";
import AllLocations from "../../pages/impact_dashboard/location_dashboard/AllLocations";
import File501 from "../../pages/scholarship_applications/File501";
import AllScholarshipApplications from "../../pages/scholarship_applications/AllScholarshipApplications";
import Leaderboard from "../../pages/impact_dashboard/leaderboard/Leaderboard";

import COhatchDocuments from "../../pages/cohatch_documents/COhatchDocuments";
import SendDocumentPage from "../../pages/cohatch_documents/SendDocumentPage";
import AllDocuments from "../../pages/cohatch_documents/AllDocuments";

function App() {

  return (
    <>
      <Routes>
        <Route element={<UserProvider />}>
          <Route element={<PersistAuth />}>
            <Route element={<AuthUser />}>
              <Route element={<NotificationProvider />}>
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<DashboardHome />} />

                  <Route path="community-managers" element={<CommunityManagers />}>
                    <Route index element={<CommunityManagersOfficernd />} />
                    <Route path="create-member" element={<CommunityManagersCreateMember />} />
                    <Route path="referrals" element={<CommunityManagersReferrals />} />
                    <Route path="member-plan" element={<MemberPlan />} />
                    {/* <Route path="bookings" element={<CommunityManagersBookings />} /> */}
                  </Route>

                  <Route path="cohatch-documents" element={<COhatchDocuments />}>
                    <Route index element={<SendDocumentPage />} />
                    <Route path="all-documents" element={<AllDocuments />} />
                  </Route>

                  <Route path="technical-support" element={<TechnicalSupport />}>
                    <Route index element={<TechnicalSupportContact />} />
                    <Route path="stories" element={<ImpactStoryTimeline />} />
                    <Route path="featured_stories" element={<FeaturedStories />} />
                    <Route path="groups" element={<TechnicalSupportGroups />} />
                    <Route path="coupon" element={<TechnicalSupportCoupon />} />
                    <Route path="reset_password" element={<ResetPassword />} />
                    <Route path="email_reminder" element={<EmailReminders />} />
                    {/* <Route path="property" element={<TechnicalSupportProperty />} /> */}
                    <Route path="workshop" element={<TechnicalSupportWorkshop />} />
                    <Route path="featured_workshop" element={<TechnicalSupportFeaturedWorkshop />} />
                  </Route>
                  {/*Impact */}
                  <Route path="locations" element={<AllLocations />}></Route>
                  <Route path="locations/:city" element={<Scholarships />}>
                    <Route index element={<ScholarshipApplications />} />
                    <Route
                      path="full_application"
                      element={<ApplicationModal />}
                    />
                  </Route>
                  <Route path="file/:applicationId" element={<ApplicationFile />}></Route>
                  <Route path="501file/:applicationId" element={<File501 />} />
                  <Route path="marketlevel/:market" element={<MarketLevel />}></Route>
                  <Route path="all_applications" element={<AllScholarshipApplications />}></Route>
                  <Route
                    path="profile/:applicationId"
                    element={<ApplicantProfile />}
                  />
                  <Route path="leaderboard" element={<Leaderboard />}></Route>
                  <Route path="impact" element={<ImpactHome />} />
                  {/* <Route path="reports/file/:reportId" element={<ImpactReportFile />}></Route>
                  <Route path="reports" element={<ImpactReports />}></Route> */}

                  <Route
                    path="impactstories/add"
                    element={<ImpactSurvey />}
                  ></Route>
                  <Route
                    path="impactstories/:storyId"
                    element={<StoryPage />}
                  ></Route>


                  <Route path="cohatch-documents" element={<SendDocumentPage />} />

                  <Route path="administrator" element={<Administrator />}>
                    <Route index element={<AdministratorUsers />} />
                    <Route path="roles" element={<AdministratorRoles />} />
                    <Route
                      path="referral-codes"
                      element={<AdministratorReferralCodes />}
                    />
                  </Route>
                  <Route path="notification" element={<Notification />} />
                  <Route path="settings" element={<Settings />} />
                </Route>

                <Route path="locationdashboard" element={<LocationDashboard />} />
              </Route>

              {/* Routes for Impact Dashboard */}
              <Route path="impact" element={<ImpactDashboard />}>
                <Route path="notification" element={<ImpactNotification />} />

                {/* <Route path="all_scholars" element={<AllScholars/>}/> */}
                <Route path="technical-support" element={<FeaturedImpact />}>


                  <Route path="featured_events" element={<FeaturedStories />} />
                  <Route path="featured_drives" element={<FeaturedStories />} />
                  <Route path="events" element={<Events />} />
                </Route>
              </Route>
            </Route>

            <Route element={<NoAuth />}>
              <Route path="/" element={<Login />} />
              <Route path="/reset_request" element={<ResetRequest />} />
              <Route path="self_reset_password" element={<SelfResetPassword />} />
              <Route path="/invalid" element={<NotAuthorized />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer limit={4} icon={false} />
    </>
  );
}

export default App;
