/* eslint-disable */
import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function createDocument(accessToken, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/send_document`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createDocument;

