/* eslint-disable capitalized-comments */
import { Outlet } from "react-router-dom";
import Title from "../../components/title/Title";
import LinkTab from "../../components/link_tab/LinkTab";

function COhatchDocuments() {
  return (
    <>
      <Title name="COhatch Documents" />
      <LinkTab
        items={[
          {
            link: "/dashboard/cohatch-documents",
            title: "Send Document Form",
          },
          {
            link: "/dashboard/cohatch-documents/all-documents",
            title: "All Documents",
          },
          // {
          //   link: "/dashboard/community-managers/bookings",
          //   title: "Bookings",
          // },
        ]}
      />

      <div className="mt-3">
        <Outlet />
      </div>
    </>
  );
}

export default COhatchDocuments;
